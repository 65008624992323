@font-face {
  font-family: ir_sans;
  src: url("./assets/fonts/IRANSans.ttf");
}

.q-icons {
  color: #7b809a !important;
}

.link-color {
  color: #1a73e8 !important;
}

.select-item:hover {
  background-color: #eee;
  transition: all 0.2s;
}
