/*page preloader*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 13000;
    height: 100%;
}


.preloader_image {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    background: url('../../../assets/images/preloader/preloader.gif') no-repeat 50% 50% transparent;
    margin: -50px 0 0 -50px;
}